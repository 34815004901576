.introjs-tooltipbuttons {
  text-align: left;
}
.introjs-skipbutton {
  margin-left: 5px;
}
.introjs-tooltip {
  direction: rtl;
}
.introjs-prevbutton {
  border: 1px solid #d4d4d4;
  border-left: none;
  -webkit-border-radius: 0 0.2em 0.2em 0;
  -moz-border-radius: 0 0.2em 0.2em 0;
  border-radius: 0 0.2em 0.2em 0;
}
.introjs-nextbutton {
  border: 1px solid #d4d4d4;
  -webkit-border-radius: 0.2em 0 0 0.2em;
  -moz-border-radius: 0.2em 0 0 0.2em;
  border-radius: 0.2em 0 0 0.2em;
}
.introjs-bullets ul li {
  float: right;
}
